(function($){

	var heroSlider = function(){
		$('.hero-content-slider').slick({
			// adaptiveHeight: true,
			dots: false,
			prevArrow: false,
			nextArrow: $('.next-arrow'),
			draggable: false,
			asNavFor: '.hero-img-slider, .hero-title-slider'
		});

		$('.hero-title-slider').slick({
			dots: false,
			arrows: false,
			draggable: false
		});

		$('.hero-img-slider').slick({
			dots: false,
			arrows: false,
			fade: true,
			draggable: false
		});
	};


	var stickyTab = function(){

		$('.sticky-tab').stick_in_parent({
			offset_top: 150
		});
	};

	var productDetail = function(){
		
		var setSectionMaxHeights = function(){
			$('.product-detail-info-section').each(function(){
				var thisObj = $(this);
				var thisContent = thisObj.find('.product-detail-info-content');
				var h = 'auto';
				thisObj.addClass('vis');
				// thisContent.attr('data-height', 'auto');
				thisContent.height('auto');
				
				h = thisContent.height();
				thisContent.attr('data-height', h);

				setTimeout(function(){
					thisContent.height(0);
					thisObj.removeClass('vis');
				}, 500);
			});
		};

		setSectionMaxHeights();
		optimizedResize.add(setSectionMaxHeights);

		$('.product-detail-info-title').on('click', function(){
			
			var thisObj = $(this);
			var thisParent = thisObj.parents('.product-detail-info-section');
			var thisContent = thisParent.find('.product-detail-info-content');
			var thisContentHeight = thisContent.attr('data-height');

			// thisParent.toggleClass('vis');


			if(thisParent.hasClass('vis')){
				thisParent.removeClass('vis');
				thisContent.height(0);
			}
			else{
				thisParent.addClass('vis');
				thisContent.height(thisContentHeight);
			}

			$('.product-detail-info-section').not(thisParent).removeClass('vis');
			$('.product-detail-info-content').not(thisContent).height(0);
			
		});
		
		$('.woocommerce-product-gallery__wrapper').magnificPopup({
			delegate: 'a',
			type: 'image',
			gallery:{
				enabled:true
			}
		});

		var qtyReal = $('input[name="quantity"]');
		var qtyFake = $('input.fake-input');
		var qtyVal = qtyReal.val();
		qtyFake.val(qtyVal);

		// console.log(qtyVal);
		
		$('.qty-btn.qty-down').on('click', function(){
			if(qtyVal > 1){
				qtyVal--;
				qtyReal.val(qtyVal);
				qtyFake.val(qtyVal);
			}
			// console.log(qtyVal);
		});
		$('.qty-btn.qty-up').on('click', function(){
			qtyVal++;
			qtyReal.val(qtyVal);
			qtyFake.val(qtyVal);
			// console.log(qtyVal);
		});
	};

	var quoteForm = function(){
		// $('[data-action="quote-form-popup"]').magnificPopup({
		// 	type: 'inline'
		// });

		// $('.scroll-to-anchor a, a[href^="#"]').on('click', function(e){
		$('.scroll-to-anchor').on('click', function(e){
			e.preventDefault();

			if(this.hash){
				
				var hashTarget = $(this.hash);

				if( hashTarget.length ){
					var waitTime = 0;
					
					if(!$('body').hasClass('scrolled')){
						waitTime = 501;
						$('body').addClass('scrolled');
					}

					setTimeout(function(){
						var anchorPosition = hashTarget.offset().top;
						var finalPosition = anchorPosition - $('.site-header').outerHeight();
						$('html, body').animate({scrollTop: finalPosition}, 1000);

						$( 'body' ).removeClass( 'nav-open' );

					}, waitTime);
				}
			}
		});
	};

	var siteMap = function(){
		$('[data-action="site-map-toggle"]').on('click', function(e){
			$('.footer-site-map').toggleClass('open');
		});
	};

	// var locationsMap = function(){
	// 	var mapSvg = $('#map-svg');
	// 	var mapState = mapSvg.find('g');

	// 	$('[data-action="map-marker"]').hover(function(){
	// 		var st = $(this).attr('data-st');
	// 		$('#' + st).addClass('hover');
	// 	},
	// 	function(){
	// 		var st = $(this).attr('data-st');
	// 		$('#' + st).removeClass('hover');
	// 	});

	// 	mapState.hover(function(){
	// 		var st = $(this).attr('id');
	// 		$('.marker[data-st="' + st + '"]').addClass('hover');
	// 	},
	// 	function(){
	// 		var st = $(this).attr('id');
	// 		$('.marker[data-st="' + st + '"]').removeClass('hover');
	// 	});
	// };

	var locationsMap2 = function(){
		if($('#animated-map').length > 0){
			
			var win = $(window);
			var mapPlaying = false;
			var mapContainer = $('#map-locations');
			var map = $('#map-svg');
			var location = map.find('.marker');

			var mapScrollCheck = function(){
				var winTop = win.scrollTop() + win.innerHeight();
				var mapTop = mapContainer.offset().top;

				if(mapTop < winTop - mapContainer.outerHeight()*.7){
					if(mapPlaying == false){
						//console.log('play');
						mapPlaying = true;
						playMap();
					}
				}
				else if(mapTop >= ( winTop - mapContainer.outerHeight()*.7 ) &&  mapTop < winTop ) {
					//console.log('pause');
					//mapAnim.pause();
					mapPlaying = false;
				}
				else{
					//console.log('stop');
					//mapAnim.stop();
					resetMap();
					mapPlaying = false;
				}
			};
			optimizedScroll.add(mapScrollCheck);
			
			var rad = 0;

			var animTiming = 16;
			
			if($('body').hasClass('page-template-page_contact')){
				animTiming = 8;
			}

			
			var playMap = function(){
				if(mapPlaying == true){
					
					if( rad < 600){
						location.each(function(){
							rad = parseInt( $(this).attr('r') );
							$(this).attr('r', rad+2);
						});
					}
					else{
						mapPlaying = false;
					}
					
					// if( window.requestAnimationFrame ){
					// 	window.requestAnimationFrame( playMap );
					// } else {
					// 	setTimeout( playMap, 66 );
					// }

					setTimeout( playMap, animTiming );
				}
			}
			
			var resetMap = function(){
				rad = 0;
				location.each(function(){
					$(this).attr('r', 8.7);
				});
				mapPlaying = false;
			}
		}
	};

	var blogFunction = function(){
		if($('.single-content-side').length > 0){

			$('.single-content-side').stick_in_parent({
				offset_top: 150
			});
		}
	};

	$(document).ready(function(){
		heroSlider();
		// stickyTab();
		productDetail();
		quoteForm();
		siteMap();
		blogFunction();
		// locationsMap();
		locationsMap2();
	});

})(jQuery);